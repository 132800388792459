<!-- 招生系统基础配置 -- 中考科目管理 -- 列表 -->
<template>
	<div>
    <list-template :current-page="page" :loading="loading" :table-config="tableConfig" :table-data="tableData" :total="total" has-add @onAdd="$router.push('./add')"
                   @onChangePage="handleCurrentChange" @onHandle="handleEdit"></list-template>

		<!-- 历史记录 -->
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>

	</div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
	data() {
		return {
			loading: true,
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [{
				prop: "plan_name",
				label: "分期计划名称"
			}, {
				prop: "goods_name",
				label: "分期商品名称"
			}, {
				prop: "plan_num",
				label: "总期数"
			},
				// {
				// 	prop: "send_msg",
				// 	label: "是否短信催交"
				// },
			{
				prop: "first_percentage",
				label: "首次缴费金额占比"
			}, {
				prop: "handle",
				label: "操作",
				width: "80rem",
				handle: true,
				render() {
					return ["查看"]
				}
			}],

			historyDialogShow: false,
			historyList: []
		}
	},
	activated() {
		this.getData()
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		getData() {
			this.loading = true;
			this.$_register('/api/recruit/periodization-plan/list', {params: {page: this.page}}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => this.loading = false);
		},

		handleEdit(row) {
      this.$router.push('./look?id=' + row.id)
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData()
		},

	},
}
</script>

<style lang="scss" scoped>
::v-deep .history-dialog {
	width: fit-content !important;
	min-width: 900rem !important;
	max-width: 1600rem !important;
	max-height: 71vh !important;
	overflow-y: auto;
}

.role-list {
	padding-top: 24rem;
}

.button {
	margin-left: 34rem;
	margin-bottom: 30rem;
	box-sizing: border-box;
	width: 90rem;
	height: 34rem;
	opacity: 1;
	border-radius: 4rem;
}

.table {
	margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	text-align: left;
	font-size: 14rem;
	font-weight: normal;
	height: 48rem;
	box-sizing: border-box;
	color: #333333;
}

.header {
	position: fixed;
	top: 120rem;
	left: 300rem;
	font-size: 16rem;
	font-weight: bold;
	line-height: 24rem;
	color: #333333;
	letter-spacing: 3.5rem;
}

.el-button--text {
	margin-right: 40rem;
	font-size: 14rem;
	font-weight: normal;
	color: #3491fa;
}
</style>
